import { Inject, Injectable, TransferState } from '@angular/core';
import {
  UrlTree,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, REFRESH_TOKEN, TOKEN, USERNAME } from '@/app/shared/services/auth.service';
import { CommonService } from '@/app/shared/services/common.service';
import { DOCUMENT } from '@angular/common';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
    private commonService: CommonService,
    private cookieService: SsrCookieService,
    @Inject(DOCUMENT) private document: Document,
    private transferState: TransferState
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token = this.transferState.get(TOKEN, '');
    if (!token) {
      this.router.navigateByUrl('/auth/login');
      return false;
    }

    return true;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    // const token = this.cookieService.get('token');

    const token = this.transferState.get(TOKEN, '');

    // const userName = this.cookieService.get('username');
    const userName = this.transferState.get(USERNAME, '');


    let jwtToken = this.commonService.parseJwt(token!);
    if (!jwtToken || jwtToken.UserName != userName) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
