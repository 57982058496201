import { Inject, Injectable } from '@angular/core';
import {
  UrlTree,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  NavigationEnd,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, TOKEN } from '@/app/shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '@/app/shared/services/common.service';
import { DOCUMENT } from '@angular/common';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { TransferState } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class AccountGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
    private commonService: CommonService,
    private cookieService: SsrCookieService,
    @Inject(DOCUMENT) private document: Document,
    private transferState: TransferState
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // Redirect to the login page
    // const localStorage = this.document.defaultView?.localStorage;
    const token = this.transferState.get(TOKEN, '');
    if (!token) {
      return this.router.navigateByUrl('/', { replaceUrl: true });
    }

    return true;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    const token = this.transferState.get(TOKEN, '');
    if (!token) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
