<app-nav-bar
  [data]="themeOption"
  [sticky]="sticky"
></app-nav-bar>
<router-outlet></router-outlet>
<app-footer [footer]="setLogo().footer" [data]="themeOption"></app-footer>

<div class="theme-option">
  <app-back-to-top></app-back-to-top>
</div>

<div class="fab-wrapper">
  <input id="fabCheckbox" type="checkbox" class="fab-checkbox" />
  <label class="fab d-grid justify-content-center align-items-center " for="fabCheckbox">
    <span class="fab-dots fab-dots-1"></span>
    <span class="fab-dots fab-dots-2"></span>
    <span class="fab-dots fab-dots-3"></span>
  </label>
  <div class="fab-wheel">
    <a href="tel:0878929789" class="fab-action fab-action-1">
        <img src="assets/svg/phone-call 1.svg" style="width: 56px;" alt="phone-call" />
    </a>
    <a href="https://zalo.me/1045103869558101344" class="fab-action fab-action-2">
        <img  src="assets/svg/zalo 1.svg" style="width: 56px" alt="phone-call" />
    </a>
    <a href="https://www.facebook.com/giathuochapucom" class="fab-action fab-action-3">
        <img src="assets/svg/messenger 1.svg" style="width: 56px;" alt="phone-call" />
    </a>
  </div>
</div>

@if(isBrowser && PopupData.src) {
  <div class="box-popup" *ngIf="isPopup" (click)="closePopup()" style="display: flex;">
    <div class="popup">
      <div class="btn-clear" (click)="closePopup()">x</div>
      <div class="popup-content">
        <a [href]="this.PopupData.link" class="link-popup">
            <img [src]="this.PopupData.src" class="img-fluid" alt="">
        </a>
      </div>
    </div>
  </div>
}

<app-registry-modal-v2 #registryModal></app-registry-modal-v2>