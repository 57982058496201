import {
  Inject,
  Injectable,
  Optional,
  PLATFORM_ID,
  TransferState,
} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders,
  HttpClient,
  HttpResponse,
} from '@angular/common/http';
import {
  catchError,
  firstValueFrom,
  from,
  Observable,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { Router } from '@angular/router';
import {
  AuthService,
  REFRESH_TOKEN,
  TOKEN,
} from '@/app/shared/services/auth.service';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { CommonService } from '@/app/shared/services/common.service';
import { environment } from '@/environments/environment';
import { REQUEST } from '@/express.tokens';
import { jwtDecode } from 'jwt-decode';
import e from 'express';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public isMaintenanceModeOn: boolean = false;
  private isRefreshing = false;
  token = '';

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private cookieService: SsrCookieService,
    private commonService: CommonService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(REQUEST) private request: any,
    private transferState: TransferState
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // If Maintainance Mode On
    return from(this.handleRequest(next, req));
  }

  async handleRequest(next: HttpHandler, req: HttpRequest<any>) {
    if (this.isMaintenanceModeOn) {
      this.router.navigate(['/maintenance']);
    }

    this.token = this.transferState.get(TOKEN, '');

    if (this.token) {
      // console.log('token', token);
      // const jwt = this.commonService.parseJwt(token);
      const jwt = jwtDecode(this.token);
      if (jwt && jwt.exp! < Date.now() / 1000) {
        const refreshToken = this.transferState.get(REFRESH_TOKEN, '');
        if (!this.isRefreshing) {
          this.isRefreshing = true;

          const refreshTokenPayload = {
            refreshToken: refreshToken,
          };
          const resRefreshtoken = await firstValueFrom(this.authService
            .refreshTokenInterceptor(JSON.stringify(refreshTokenPayload))).catch((err) => {
            console.log(err);
            this.authService.logout();
            }) as any;
          if (resRefreshtoken) {
            this.isRefreshing = false;
            this.authService.setCookie('token', resRefreshtoken.jwtToken);
            this.authService.setCookie('refreshToken', resRefreshtoken.refreshToken);
            this.transferState.set(TOKEN, resRefreshtoken.jwtToken);
            this.transferState.set(REFRESH_TOKEN, resRefreshtoken.refreshToken);
          }
          else {
            this.authService.logout();
            throw new Error(
              'Có lỗi xảy ra khi làm mới token, vui lòng tải lại trang hoặc đăng nhập lại!')
          }
        }
      }

      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.token}`,
          'Cache-Control':
            'no-store, no-cache, must-revalidate, post-check=0, pre-check=0',
        },
      });
      return next
        .handle(req)
        .pipe(
          tap((event) => {
            return this.setResponseHeaders(event);
          })
        )
        .toPromise();
    }

    return next
      .handle(req)
      .pipe(
        tap((event) => {
          return this.setResponseHeaders(event);
        }),
        catchError((error: HttpErrorResponse): any => {
          if (error.status === 401) {
            const refresh = this.transferState.get(REFRESH_TOKEN, '');
            if (refresh) {
              const refreshToken = {
                refreshToken: refresh,
              };
              return this.authService
                .refreshTokenInterceptor(JSON.stringify(refreshToken))
                .pipe(
                  switchMap((res: any): any => {
                    if (res && res.jwtToken && res.refreshToken) {
                      this.isRefreshing = false;
                      this.authService.setCookie('token', res.jwtToken);
                      this.authService.setCookie(
                        'refreshToken',
                        res.refreshToken
                      );
                      this.transferState.set(TOKEN, res.jwtToken);
                      this.transferState.set(REFRESH_TOKEN, res.refreshToken);
                      return next.handle(
                        req.clone({
                          setHeaders: {
                            Authorization: `Bearer ${res.jwtToken}`,
                          },
                        })
                      );
                    } else {
                      this.authService.logout();
                      throw new Error(
                        'Có lỗi xảy ra khi làm mới token, vui lòng tải lại trang hoặc đăng nhập lại!'
                      );
                    }
                  }),
                  catchError((err) => {
                    this.isRefreshing = false;
                    console.log(err);
                    this.authService.logout();
                    return throwError(() => err);
                  })
                );
            }
          } else {
            if (error.status === 404) {
              this.router.navigateByUrl('error', { replaceUrl: true });
            }
          }
          return throwError(() => error);
        })
      )
      .toPromise();
  }

  setResponseHeaders(event: any) {
    if (event instanceof HttpResponse) {
      event = event.clone({
        headers: event.headers
          .set(
            'Cache-Control',
            'no-store, no-cache, public, must-revalidate, proxy-revalidate, max-age=0'
          )
          .set('Pragma', 'no-cache')
          .set('Expires', '0')
          .set('Surrogate-Control', 'no-store'),
      });
    }
    return event;
  }
}
